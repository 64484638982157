import React, {useState} from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import logo from "../img/logo.svg";
import {motion} from "framer-motion";
import {FaPhone} from "react-icons/fa";

const KarolBookScript = () => (
  <iframe scrolling="no" frameBorder="0" id="widget-preview-iframe"
          src="//www.znanylekarz.pl/ajax/marketing/doctor/widget/big_with_calendar/karol-jablonski?hide_branding=true"
          className="big_with_calendar" style={{width: 100 + '%', minHeight: 645 + 'px', position: 'absolute'}}></iframe>
);

const PatrycjaBookScript = () => (
  <iframe scrolling="no" frameBorder="0" id="widget-preview-iframe"
          src="//www.znanylekarz.pl/ajax/marketing/doctor/widget/big_with_calendar/patrycja-drapala?hide_branding=true"
          className="big_with_calendar" style={{width: 100 + '%', minHeight: 645 + 'px', position: 'absolute'}}></iframe>
);


// eslint-disable-next-line
export const BookingPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;
  const team = {blurbs: [
      {title: 'Karol', content: getKarolBooking},
      {title: 'Patrycja', content: getPatrycjaBooking},
      {title: 'Regulamin', content: getTerms},
  ]};
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url('/img/booking.jpg')`,
          filter: "contrast(.5)",
          backgroundPosition: "center"
        }}
      >
        <h1
          className="has-text-weight-bold is-size-1 has-text-centered"
          style={{
            color: "white",
            padding: "1rem",
          }}
        >
          Umów wizytę
        </h1>
      </div>
      <section className="section">
        <div className="container">

          <div className="tabs is-large">
            <ul>
              {
                team.blurbs.map(
                  (data, idx) => (<li key={`${idx}`} className={activeIndex === idx ? 'is-active' : null}>
                    <a onClick={() => setActiveIndex(idx)}>{data.title}</a>
                  </li>)
                )
              }
            </ul>
          </div>
          {
            team.blurbs.map((data, idx) =>
              ( activeIndex === idx ?
                  <motion.div
                    initial={{
                      opacity: 0,
                      x: -200
                    }}
                    animate={{
                      opacity: 1,
                      x: 0
                    }}
                    exit={{
                      opacity: 0,
                      x: 200
                    }}
                    className="card m-0">
                    {data.content()}
                  </motion.div> : null
              )
            )
          }


        </div>
      </section>
    </div>
  );
};

const getKarolBooking = () => {
  return (
    <div className="columns">
    <div className="column is-flex is-align-items-start is-justify-content-center is-relative booking-container">
      <div className="anim-bounce m-6">
        <img src={logo} alt="Dzika Terpia Logo" style={{ width: "40px" }} />
      </div>
      <KarolBookScript />
    </div>
    <div className="column is-two-thirds p-6">
      <h2 className="title">Pierwsza wizyta</h2>
      <p>
        Drogi pacjencie, pierwsza wizyta fizjoterapeutyczna składa się z obszernego wywiadu, zapoznania się z dokumentacją medyczną, badania funkcjonalnego, ortopedycznego i/lub neurologicznego, analizy badań obrazowych (RTG, USG, REZONANS MAGNETYCZNY), a następnie omówienia planu terapii. W tym celu proszę o zabranie ze sobą niekrępującej bielizny, która pozwoli na dokładne badanie przedmiotowe uwzględniając wszystkie dysfunkcyjne miejsca w ciele, a także całej dokumentacji medycznej (w tym płyty z badań obrazowych).
      </p>
      <p className="mt-4">
        Pacjencie – jeśli nie posiadasz dokumentacji medycznej – nic nie szkodzi. Rzetelne badanie fizjoterapeutyczne pozwoli na zakwalifikowanie, bądź wykluczenie danego przypadku z procesu rehabilitacji (jeśli badanie wykaże jakiekolwiek przeciwskazania tzw. ,,red flags”).
      </p>
      <div className="is-flex is-size-4 mt-6 is-align-items-center gap-1">
        <FaPhone />
        <div>+48 733 441 315</div>
      </div>
    </div>
  </div>
  );
}

const getPatrycjaBooking = () => {
  return (
    <div className="columns">
      <div className="column is-flex is-align-items-start is-justify-content-center is-relative booking-container">
        <div className="anim-bounce m-6">
          <img src={logo} alt="Dzika Terpia Logo" style={{ width: "40px" }} />
        </div>
        <PatrycjaBookScript />
      </div>
      <div className="column is-two-thirds p-6">
        <h2 className="title">Pierwsza wizyta</h2>
        <p>
          Drogi pacjencie, pierwsza wizyta fizjoterapeutyczna składa się z obszernego wywiadu, zapoznania się z dokumentacją medyczną, badania funkcjonalnego, ortopedycznego i/lub neurologicznego, analizy badań obrazowych (RTG, USG, REZONANS MAGNETYCZNY), a następnie omówienia planu terapii. W tym celu proszę o zabranie ze sobą niekrępującej bielizny, która pozwoli na dokładne badanie przedmiotowe uwzględniając wszystkie dysfunkcyjne miejsca w ciele, a także całej dokumentacji medycznej (w tym płyty z badań obrazowych).
        </p>
        <p className="mt-4">
          Pacjencie – jeśli nie posiadasz dokumentacji medycznej – nic nie szkodzi. Rzetelne badanie fizjoterapeutyczne pozwoli na zakwalifikowanie, bądź wykluczenie danego przypadku z procesu rehabilitacji (jeśli badanie wykaże jakiekolwiek przeciwskazania tzw. ,,red flags”).
        </p>
        <div className="is-flex is-size-4 mt-6 is-align-items-center gap-1">
          <FaPhone />
          <div>+48 697 468 627</div>
        </div>
      </div>
    </div>
  );
}

const getTerms = () => {
  return (
    <div className="columns">
      <div className="column px-6 pt-3">
        <h2 className="title">Regulamin</h2>
        <p className="pt-3">
          gabinetu fizjoterapii Dzika Terapia – fizjoterapia i osteopatia Karol Jabłoński<br />
          obowiązuje od 1.07.22r.<br />
        </p>
        <p className="pt-3">
          <h5 className="is-size-5">§1 Podstawa prawna</h5>
          1. Dzika Terapia – fizjoterapia i osteopatia Karol Jabłoński (dalej Gabinet) jest firmą, w której świadczone są usługi fizjoterapeutyczne w ramach praktyki zawodowej.<br />
          2. Gabinet znajduje się w Tychach, przy ulicy Starokościelnej 1/1.<br />
          3. Firma posiada wpis do Rejestru Praktyk Zawodowych Fizjoterapeutów o nr 000000252975 z dn. 1.07.2022.<br />
          4. Numer konta firmowego: 80109016520000000150265384<br />
        </p>
        <p className="pt-3">
          <h5 className="is-size-5">§2 Postanowienia ogólne</h5>
          1. Regulamin określa zasady świadczenia usług fizjoterapeutycznych w ramach wykonywania zawodu fizjoterapeuty.<br />
          2. Niniejszy Regulamin jest przekazywany każdemu Pacjentowi podczas pierwszej wizyty w gabinecie.<br />
          3. Nieznajomość Regulaminu nie zwalnia z przestrzegania jego zasad.<br />
        </p>

        <p className="pt-3">
          <h5 className="is-size-5">§3 Cele i zadania</h5>
          1. Celem działania Gabinetu jest świadczenie przez wykwalifikowany personel usług z zakresu fizjoterapii i osteopatii.<br />
          2. Do zadań Gabinetu należy w szczególności zapewnienie wysokiej jakości świadczonych usług fizjoterapeutycznych, z zastosowaniem znanych i sprawdzonych metod terapeutycznych, przestrzeganie etyki zawodowej, działanie promujące profilaktykę zdrowia, przestrzeganie praw Pacjenta oraz świadczenie usług z uwzględnieniem potrzeb i bezpieczeństwa Pacjenta.<br />
        </p>

        <p className="pt-3">
          <h5 className="is-size-5">§4 Czas i miejsce świadczenia usług</h5>
          1. Gabinet Dzika Terapia jest otwarty od poniedziałku do soboty, z wyłączeniem świąt państwowych.<br />
          2. Miejscem świadczenia usług fizjoterapeutycznych jest siedziba Gabinetu.<br />
          3. Zarówno pomieszczenie Gabinetu, jak i wykorzystywane w nim urządzenia spełniają wymagania, jakim powinny odpowiadać podmioty wykonujące działalność leczniczą, w tym także praktykę zawodową fizjoterapeutów.<br />
        </p>

        <p className="pt-3">
          <h5 className="is-size-5">§5 Zasady rezerwacji i odwoływania terapii</h5>
          1. Rezerwacji wizyty można dokonać telefonicznie pod nr tel.: 733 441 315 lub internetowo, przez portal znanylekarz.pl<br />
          2. Dokonując rezerwacji należy podać imię, nazwisko i numer telefonu do kontaktu.<br />
          3. Istnieje możliwość przeniesienia wizyty na inny termin pod warunkiem dostępności wolnych miejsc i telefonicznym uprzedzeniu o zmianie co najmniej 24 godziny przed umówioną wizytą.<br />
          4. Odwołania wizyty można dokonać telefonicznie nie później niż 24 godzin przed umówionym terminem.<br />
          5. Odwołanie wizyty później niż 24 godzin przed zaplanowanym terminem skutkuje pobraniem opłaty rezerwacyjnej następnej wizyty (zadatku) w wysokości 50% jego ceny, który to przepada z chwilą niepojawienia się w umówionym czasie. Przedpłata powinna pojawić się na koncie do 48 godzin przed umówioną wizytą. W przypadku gdy przedpłata nie wpłynie na konto firmy w wyznaczonym terminie, wizyta zostaje anulowana automatycznie.<br />
          6. W przypadku dwukrotnego niestawienia się w ustalonym terminie, podczas rezerwacji kolejnego zabiegu Pacjent zobowiązany jest do zapłaty zadatku w wysokości 100% jego ceny, który to przepada z chwilą niepojawienia się w umówionym czasie. Przedpłata powinna pojawić się na koncie do 48 godzin przed umówioną wizytą. W przypadku gdy przedpłata nie wpłynie na konto firmy w wyznaczonym terminie, wizyta zostaje anulowana automatycznie, a pacjent zostanie pozbawiony możliwości zapisania się na kolejną wizytę.<br />
        </p>

        <p className="pt-3">
          <h5 className="is-size-5">§6 Organizacja i przebieg wizyty</h5>
          1. Pacjent proszony jest o przybycie na wizytę kilka minut wcześniej przed wyznaczoną godziną.<br />
          2. Spóźnienie się na wizytę skutkuje skróceniem czasu terapii.<br />
          3. Podczas pierwszej wizyty zostanie przeprowadzony wywiad i badanie przedmiotowe, a także, na ich podstawie, określony cel i plan terapii.<br />
          4. Przystępując do terapii Pacjent wyraża zgodę na jej przeprowadzenie.<br />
        </p>

        <p className="pt-3">
          <h5 className="is-size-5">§7 Prawa i obowiązki Pacjenta</h5>
          1. W czasie korzystania z usług Gabinetu, Pacjent ma prawo do:<br />
          a. poszanowania godności oraz uprzejmego traktowania przez personel Gabinetu,<br />
          b. świadczeń wykonywanych przez personel o odpowiednich kwalifikacjach,<br />
          c. uzyskania rzetelnej informacji o swoim stanie zdrowia, proponowanych metodach terapeutycznych, następstwach ich zastosowania lub zaniechania,<br />
          d. pełnej dyskrecji jego osoby,<br />
          e. pomocy i wyjaśnień o sposobie przygotowania do zabiegu,<br />
          f. wyrażenia zgody lub odmowy wykonania zabiegu po uzyskaniu odpowiednich informacji.<br />
        </p>

        <p className="pt-3">
          <h5 className="is-size-5">§8 Vouchery podarunkowe</h5>
          1. W Gabinecie dostępne są vouchery podarunkowe, które można nabyć w siedzibie firmy oraz  drogą elektroniczną, na dowolną kwotę.<br />
          2. Voucher można wykorzystać na zabiegi fizjoterapeutyczne z oferty Gabinetu.<br />
          3. Voucher realizowany jest w godzinach pracy Gabinetu.<br />
          4. Zarówno rezerwacja, jak i odwołanie wizyty z voucheru odbywa się na zasadach ogólnych, określonych w §5 Regulaminu.<br />
          5. Voucher ważny jest od daty zakupu do terminu ważności wpisanego bezpośrednio na voucherze. Termin ważności uwarunkowany jest ilością zakupionych wizyt (1 wizyta = 1 msc ważności).<br />
          6. Voucher nie podlega wymianie na gotówkę.<br />
          7. Voucher nie łączy się z innymi promocjami/ bonami.<br />
        </p>
        <p className="pt-3">
          <h5 className="is-size-5">§9 Przepisy porządkowe</h5>
          1. Na terenie Gabinetu istnieje bezwzględny zakaz palenia tytoniu, spożywania napojów alkoholowych oraz bycia pod ich wpływem, a także zażywania środków odurzających.<br />
          2. Pacjenci przebywający na terenie Gabinetu zobowiązani są do poszanowania mienia będącego jego własnością oraz należącego do innych Pacjentów, jak również do zachowania czystości.<br />
          3. Za zniszczenie mienia, o którym mowa w ust. 2, Pacjenci ponoszą pełną odpowiedzialność finansową.<br />
        </p>

        <p className="pt-3">
          <h5 className="is-size-5">§10 Postanowienia końcowe</h5>
          1. Każdy Pacjent zobowiązany jest do zapoznania się z Regulaminem i przestrzegania jego zasad.<br />
          2. W sprawach nieuregulowanych niniejszym Regulaminem mają zastosowanie przepisy Kodeksu Cywilnego oraz inne obowiązujące przepisy prawa.<br />
          3. Gabinet Dzika Terapia zastrzega sobie prawo do zmiany treści Regulaminu. O każdej zmianie Pacjent zostanie poinformowany telefonicznie, za pośrednictwem wiadomości SMS.<br />
          4. Niewyrażenie zgody na postanowienia Regulaminu jest równoznaczne z brakiem możliwości korzystania z usług Gabinetu.<br />
          5. Wszelkie skargi/ wnioski Pacjent może składać na piśmie za pośrednictwem poczty elektronicznej na gabinet.dzikaterapia@gmail.com. Wszystkie skargi/ wnioski rozpatrywane są w terminie 30 dni roboczych od dnia ich wpływu.<br />
        </p>

        <p className="pt-3">
          <h5 className="is-size-5">§11 Przetwarzanie i ochrona danych osobowych</h5>
          1. Dane osobowe Pacjenta przetwarzane są na zasadach opisanych w Rozporządzeniu Parlamentu<br />
          Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych<br />
          w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych<br />
          oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).<br />
          2. Obowiązek informacyjny - art. 13 RODO przekazywany jest Pacjentowi podczas pierwszej wizyty jako odrębny dokument.<br />
        </p>
      </div>
    </div>
  );
}

BookingPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const BookingPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BookingPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

BookingPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BookingPage;

export const bookingPageQuery = graphql`
  query BookingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
